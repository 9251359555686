import { useCallback } from 'react'
import type { AnalyticsContext } from '~/lib/client-server/cms/modules'
import { fireImpressionEvent, fireClickthroughEvent } from '~/lib/client-server/cms/sources/mcp/hero/analytics'
import { McpCampaignStatType } from '~/lib/client-only/mcp/mcp.constants'
import type { ContentSource } from '~/lib/client-server/cms/types'
import { allowedInteractionSources } from '~/lib/client-server/cms/types'
import type { ProductSource } from '~/components/providers/ProductProvider/ProductProvider'
import { createClientLogger } from '~/lib/logger'

const logger = createClientLogger('mcp')

/**
 * Custom hook that provides interaction handlers for personalization.
 *
 * @param {Object} options - The options for the hook.
 * @param {AnalyticsContext} options.context - The analytics context.
 * @param {ContentSource | ProductSource} options.source - The content source type or product source.
 * @returns {Object} - The interaction handlers.
 * @returns {Function} handleImpressionEvent - The handler for impression events.
 * @returns {Function} handleClickThroughEvent - The handler for click-through events.
 *
 * @example
 * const { handleImpressionEvent, handleClickThroughEvent } = usePersonalizationInteractionHandlers({context, source});
 * handleImpressionEvent();
 * handleClickThroughEvent();
 */
export const usePersonalizationInteractionHandlers = ({
	context,
	source,
}: {
	context?: AnalyticsContext
	source?: ContentSource | ProductSource
}) => {
	const handleInteraction = useCallback(
		(statType: McpCampaignStatType) => {
			// Only fire events if the context and source are defined and the source is allowed to trigger interaction events.
			if (context && source && allowedInteractionSources.includes(source)) {
				switch (statType) {
					case McpCampaignStatType.IMPRESSION:
						fireImpressionEvent(context)
						break
					case McpCampaignStatType.CLICKTHROUGH:
						fireClickthroughEvent(context)
						break
					default:
						logger.error(`Invalid stat type: ${statType}`)
						throw new Error(`Invalid stat type: ${statType}`)
				}
			}
		},
		[context, source],
	)

	const createInteractionHandler = useCallback(
		(statType: McpCampaignStatType) => () => handleInteraction(statType),
		[handleInteraction],
	)

	return {
		handleImpressionEvent: createInteractionHandler(McpCampaignStatType.IMPRESSION),
		handleClickThroughEvent: createInteractionHandler(McpCampaignStatType.CLICKTHROUGH),
	}
}
