'use client'

import parse from 'html-react-parser'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'lodash.isequal'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import type { PromoBannerAsset } from '~/lib/types/promo-banner.interface'
import styles from './PromoBanner.module.scss'
import useGetBanners from '~/components/hooks/useGetBanners'
import { usePersonalizationInteractionHandlers } from '~/components/hooks/personalization/usePersonalizationInteractionHandlers'
import clsx from 'clsx'
import { ensureNonNullishArray } from '~/types/strict-null-helpers'
import { AnimationBanners } from '../AnimationBanners/AnimationBanners'
import useReactiveActor from '~/components/hooks/useReactiveActor'
import { bannersStateMachine } from '~/components/cms/ContentSlot/statemachines/bannersStateMachine'
import { matchesAny } from '~/lib/client-server/statemachine/statemachine-utils'
import './PromoBanner.scss'

/**
 * `PromoBannerContent` is a component that displays a promotional banner with optional tooltip.
 * It also handles user interactions such as mouse enter, mouse out, and click events.
 *
 * @param {Object} props - The properties that define the component.
 * @param {PromoBannerAsset} props.banner - The banner object containing the body, headerPromoTooltipTitle, and headerPromoTooltip.
 * @param {PromoBannerAsset} props.personalizedPromoBanner - The personalized promo banner object containing campaign, experience, and user group.
 * @param {boolean} props.includeMcpPromo - A flag indicating whether to include MCP promo.
 *
 * @returns {JSX.Element} The PromoBannerContent component.
 */
export const PromoBannerContent = ({
	banner,
	personalizedPromoBanner,
	includeMcpPromo,
}: {
	banner?: PromoBannerAsset
	personalizedPromoBanner?: PromoBannerAsset
	includeMcpPromo?: boolean
}) => {
	const [tooltip, setTooltip] = useState(false)
	const { analyticsManager } = useAnalytics()

	const bannerBody = useMemo(() => {
		return banner?.body ? parse(banner.body) : null
	}, [banner?.body])

	// Campaign, experience, and user group fields are accessible only from the personalization promo asset.
	const personalizationContext = useMemo(
		() => ({
			context: {
				campaign: personalizedPromoBanner?.campaign || undefined,
				experience: personalizedPromoBanner?.experience || undefined,
				userGroup: personalizedPromoBanner?.userGroup || undefined,
			},
			source: personalizedPromoBanner?.source,
		}),
		[personalizedPromoBanner],
	)

	const { handleClickThroughEvent, handleImpressionEvent } =
		usePersonalizationInteractionHandlers(personalizationContext)
	const handleClickInteraction = useCallback(() => {
		if (includeMcpPromo) {
			handleClickThroughEvent()
		}
	}, [includeMcpPromo, handleClickThroughEvent])

	useEffect(() => {
		if (tooltip) {
			analyticsManager.fireModalOpened({
				site_modal: 'sitewide: promo banner',
			})
		}
	}, [analyticsManager, tooltip])

	useEffect(() => {
		// Note: We only want to track impressions when the tooltip is opened and the banner is the personalized promo banner
		if (tooltip && isEqual(banner, personalizedPromoBanner)) {
			handleImpressionEvent()
		}
	}, [tooltip, handleImpressionEvent, banner, personalizedPromoBanner])

	return (
		<div className={clsx(styles['promo-banner-message-wrapper'])}>
			<div className={styles['promo-banner-message']}>
				<div
					onMouseOut={() => setTooltip(false)}
					onMouseEnter={(e) => {
						if (e.target instanceof Element) {
							setTooltip(e.target.className === 'promo-tooltip-link' ?? false)
						}
					}}
					onClick={(e) => {
						// Note: We only want to track clicks on the CTA, not the tooltip
						if (e.target instanceof Element && e.target.className !== 'promo-tooltip-link') {
							handleClickInteraction()
						}
					}}
				>
					{bannerBody}
				</div>
			</div>
			{banner?.headerPromoTooltipTitle && tooltip && (
				<div className={styles['tooltip-container']}>
					<div className={styles['tooltip-triangle']} />
					<div className={styles['tooltip-content']}>
						<p className={styles['tooltip-header']}>{banner.headerPromoTooltipTitle}</p>
						<p className={styles['tooltip-body']}>{banner.headerPromoTooltip}</p>
					</div>
				</div>
			)}
		</div>
	)
}

/**
 * `PromoBanner` is a React component that displays a promotional banner.
 *
 * @component
 * @param {PromoBannerAsset} promoAsset - The promotional asset to be displayed in the banner.
 * @returns {React.Element} Returns a promotional banner if the device is not mobile and there is a promotional asset body. Otherwise, it returns null.
 *
 * @example
 * <PromoBanner promoAsset={promoAsset} />
 */
const PromoBanner = ({ promoAsset: promoAssetProps }: { promoAsset?: PromoBannerAsset }) => {
	const assets = useGetBanners(undefined, promoAssetProps)
	const { promoBanner, personalizedPromoBanner } = assets
	const banners = useMemo(
		() => ensureNonNullishArray([personalizedPromoBanner, promoBanner]),
		[personalizedPromoBanner, promoBanner],
	)

	const [actorState] = useReactiveActor(bannersStateMachine, {
		input: {
			hasSsfcHeaderData: false,
			hasSsfcPromoData: !!promoBanner,
			hasMcpPromoData: !!personalizedPromoBanner,
		},
	})

	const showNothing = actorState.matches({ desktop: { promo: 'showNothing' } })
	const includeMcpPromo = matchesAny(actorState, [
		{ mobile: 'showMcpPromo' },
		{ mobile: 'cycleSsfcHeaderAndMcpPromo' },
		{ mobile: 'cycleSsfcAndMcpPromo' },
		{ mobile: 'cycleAllData' },
	])

	if (showNothing) {
		return null
	}

	return (
		<div className={clsx(styles['promo-banner'])}>
			<hr className={clsx(styles['header-divider'], styles.top)} />
			<AnimationBanners
				banners={banners}
				personalizedPromoBanner={personalizedPromoBanner}
				component={({ banner }) => (
					<PromoBannerContent
						banner={banner}
						personalizedPromoBanner={personalizedPromoBanner}
						includeMcpPromo={includeMcpPromo}
					/>
				)}
			/>
			<hr className={clsx(styles['header-divider'], styles.bottom)} />
		</div>
	)
}

export default memo(PromoBanner)
