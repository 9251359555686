import styles from './AnimationBanners.module.scss'
import clsx from 'clsx'
import type { HeaderBannerAsset } from '~/lib/types/header-banner.interface'
import type { PromoBannerAsset } from '~/lib/types/promo-banner.interface'

export type BannerType = PromoBannerAsset | HeaderBannerAsset | undefined
export type BannerComponentType = React.FC<{ banner: BannerType }>

interface AnimationBannersProps {
	banners: BannerType[]
	component?: BannerComponentType
	personalizedPromoBanner?: PromoBannerAsset
	onClick?: () => void
	className?: string
}

/**
 * `AnimationBanners` is a React component that displays a series of banners in a slideshow format.
 * It cycles through the banners every 5 seconds if there is more than one banner.
 *
 * @component
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {BannerType[]} props.banners - An array of banners to display.
 * @param {BannerComponentType} [props.component] - A React component that is used to render each banner.
 * @param {PromoBannerAsset} [props.personalizedPromoBanner] - A specific banner that, when clicked, triggers the onClick event.
 * @param {() => void} [props.onClick] - A function that is called when the personalizedPromoBanner is clicked.
 * @param {string} [props.className] - A CSS class name that is applied to the component's root element.
 *
 * @returns {React.Element} The rendered component.
 */
export const AnimationBanners = ({
	banners,
	component: Component,
	personalizedPromoBanner,
	onClick,
	className,
}: AnimationBannersProps) => {
	return (
		<>
			{banners?.map(
				(banner, index) =>
					banner?.promoMobileMessage &&
					Component && (
						<div
							key={banner.id}
							// The classNames are conditionally applied based on the number of banners.
							className={clsx(
								{
									[styles['one-banner']]: banners.length === 1,
									[styles['two-banners']]: banners.length === 2,
									[styles['three-banners']]: banners.length === 3,
								},
								styles.banner,
								styles[`banner-${index}`],
								className,
							)}
							onClick={banner === personalizedPromoBanner ? onClick : undefined}
						>
							<Component banner={banner} />
						</div>
					),
			)}
		</>
	)
}
