import { useEffect, useState, useCallback, useMemo } from 'react'
import type { AnyActorLogic, ActorOptions, Actor, SnapshotFrom, EventFromLogic } from 'xstate'
import { createActor } from 'xstate'
import isEqual from 'lodash.isequal'

/**
 * useReactiveActor is a custom hook designed to integrate with xstate's actor logic.
 * This hook was created because xstate does not reactively re-render when passed-in props (like context) are changed.
 * The hook is particularly useful for state machines that react purely to their passed-in context,
 * instead of relying on xstate events to transition.
 *
 * Note: Automatically starts the actor on mount and stops it on unmount. When input parameters are changed,
 * a new actor instance is created and the old one is stopped.
 *
 * @template TLogic
 * @param logic - The actor logic (state machine) to be used.
 * @param options - Optional actor configuration options.
 * @returns A tuple containing the current snapshot of the actor's state, a function to send events to the actor, and the actor instance itself.
 *
 * @example
 * import { setup } from 'xstate';
 * import useReactiveActor from '~/components/hooks/useReactiveActor';
 *
 * const testMachine = setup({
 *   types: {
 *     context: {} as { someValue: boolean },
 *     input: {} as { someValue: boolean },
 *   },
 *   guards: {
 *     isSomeValueTrue: ({ context }) => context.someValue === true,
 *     isSomeValueFalse: ({ context }) => context.someValue === false,
 *   },
 * }).createMachine({
 *   id: 'test',
 *   initial: 'idle',
 *   context: ({ input }) => ({
 *     someValue: input.someValue,
 *   }),
 *   states: {
 *     idle: {
 *       always: [
 *         { target: 'running', guard: 'isSomeValueTrue' },
 *       ],
 *       on: { START: 'running' },
 *     },
 *     running: {
 *       always: [
 *         { target: 'idle', guard: 'isSomeValueFalse' },
 *       ],
 *       on: { STOP: 'idle' },
 *     },
 *   },
 * });
 *
 * function MyComponent() {
 *   const [snapshot, send, actor] = useReactiveActor(testMachine, { input: { someValue: true } });
 *
 *   useEffect(() => {
 *     console.log(snapshot);
 *   }, [snapshot]);
 *
 *   return (
 *     <div>
 *       <button onClick={() => send({ type: 'START' })}>Start</button>
 *       <button onClick={() => send({ type: 'STOP' })}>Stop</button>
 *       <p>Current state: {snapshot.value}</p>
 *     </div>
 *   );
 * }
 */
function useReactiveActor<TLogic extends AnyActorLogic>(
	logic: TLogic,
	options?: ActorOptions<TLogic>,
): [SnapshotFrom<TLogic>, Actor<TLogic>['send'], Actor<TLogic>] {
	const [snapshot, setSnapshot] = useState<SnapshotFrom<TLogic>>()
	const [prevOptions, setPrevOptions] = useState<ActorOptions<TLogic> | undefined>(options)

	const effectiveOptions: ActorOptions<TLogic> | undefined = useMemo(() => {
		// If the options are the same, return the previous options
		if (isEqual(options, prevOptions)) {
			return prevOptions
		}
		// Otherwise, update the previous options
		setPrevOptions(options)
		return options
	}, [options, prevOptions])

	const actor: Actor<TLogic> = useMemo(() => {
		// Create a new actor instance
		const newActor = createActor(logic, effectiveOptions)
		// Subscribe to the actor's state changes
		newActor.subscribe((state) => {
			setSnapshot(state)
		})
		// Start the actor
		newActor.start()
		return newActor
	}, [logic, effectiveOptions])

	// On unmount and changes to the logic, stop the old actor
	useEffect(() => {
		return () => {
			actor?.stop()
		}
	}, [actor])

	const send: (event: EventFromLogic<TLogic>) => void = useCallback(
		(event: EventFromLogic<TLogic>) => {
			actor?.send(event)
		},
		[actor],
	)

	// We must wait for the actor to start before we can get the snapshot, but the actor creator in useMemo
	// is reliant on the setSnapshot callback to update the snapshot state. This is a workaround to ensure
	// the snapshot is set before returning it.
	return [snapshot || actor.getSnapshot(), send, actor]
}

export default useReactiveActor
