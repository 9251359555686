'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'lodash.isequal'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import type { CustomerGroup } from '~/graphql/generated/uacapi/type-document-node'
import { CustomerAuthType } from '~/graphql/generated/uacapi/type-document-node'
import { ensureSafeCustomerGroupsArray, getBanners } from '~/lib/banners'
import type { HeaderBannerAsset } from '~/lib/types/header-banner.interface'
import type { PromoBannerAsset } from '~/lib/types/promo-banner.interface'
import { ensureArray } from '~/types/strict-null-helpers'
import { useLocale } from './useLocale'
import usePersonalization from './personalization/usePersonalization'
import { SitewideBannerImpl } from '~/lib/client-server/cms/sources/mcp/shared/sitewide.banner'
import { ContentSource } from '~/lib/client-server/cms/types'
import { createClientLogger } from '~/lib/logger'

export const logger = createClientLogger('personalization-transform')

/**
 * Transforms the input data into a PromoBannerAsset object.
 * If the input data is invalid or doesn't contain an id, it returns undefined.
 *
 * @param {unknown} data - The input data to be transformed.
 * @returns {PromoBannerAsset | undefined} The transformed PromoBannerAsset object, or undefined if the input is invalid.
 */
const transformer = (data: unknown): PromoBannerAsset | undefined => {
	try {
		const banner = new SitewideBannerImpl(data)
		if (!banner.id) {
			return undefined
		}

		return {
			id: banner.id,
			source: ContentSource.MCP,
			body: banner.body,
			promoMobileMessage: banner.promoMobileMessage,
			headerPromoTooltipTitle: banner.headerPromoTooltipTitle,
			headerPromoTooltip: banner.headerPromoTooltip,
			campaign: banner.campaign,
			experience: banner.experience,
			userGroup: banner.userGroup,
		}
	} catch (e) {
		logger.error(e, 'Invalid input data received for rendering mcp sitewide banner')
		return undefined
	}
}

/**
 * Custom React hook to fetch and manage banner assets.
 *
 * @param {HeaderBannerAsset} defaultHeaderAsset - The default header banner asset.
 * @param {PromoBannerAsset} defaultPromoAsset - The default promotional banner asset.
 * @returns {Object} An object containing the header banner, promotional banner, and personalized promotional banner.
 *
 * @example
 * const { headerBanner, promoBanner, personalizedPromoBanner } = useGetBanners(defaultHeader, defaultPromo);
 */
export default function useGetBanners(defaultHeaderAsset?: HeaderBannerAsset, defaultPromoAsset?: PromoBannerAsset) {
	const { user } = useSession()
	const locale = useLocale()
	const personalizationContext = usePersonalization()
	const customerGroupsRef = useRef<Array<CustomerGroup>>([])

	// Initialize the accountType to GUEST, since during static builds we assume a GUEST
	// context for initial banner filtering.
	const [accountType, setAccountType] = useState(CustomerAuthType.GUEST)

	const [assets, setAssets] = useState<{
		headerBanner: HeaderBannerAsset | undefined
		promoBanner: PromoBannerAsset | undefined
		personalizedPromoBanner: PromoBannerAsset | undefined
	}>({
		headerBanner: defaultHeaderAsset,
		promoBanner: defaultPromoAsset,
		personalizedPromoBanner: undefined,
	})

	const personalizedPromoAssetData = useMemo(() => {
		const campaignData = personalizationContext?.getSitewideBannerData?.()
		return campaignData ? transformer(campaignData.payload) : undefined
	}, [personalizationContext])

	const fetchBanners = useCallback(
		async (customerGroups: string[]) => {
			const [newHeaderBannerAsset, newPromoBannerAsset] = await getBanners(locale, {
				customerGroups: ensureSafeCustomerGroupsArray(customerGroups),
			})
			const newAssets = {
				headerBanner: newHeaderBannerAsset,
				promoBanner: newPromoBannerAsset,
				personalizedPromoBanner: personalizedPromoAssetData,
			}

			if (!isEqual(newAssets, assets)) {
				setAssets(newAssets)
			}
		},
		[locale, personalizedPromoAssetData, assets],
	)

	// Whenever the user changes, assign the customer groups to a reference,
	// and update the cached accountType.
	// NOTE: If a GUEST session initializes on the client, then re-setting the accountType
	// to GUEST will not trigger a re-fetch to avoid an unnecessary API call on page load.
	useEffect(() => {
		customerGroupsRef.current = ensureArray(user?.profile?.customerGroups)
		if (user?.session.accountType) setAccountType(user?.session.accountType)
	}, [user])

	// When the cached accountType changes, refetch the banners
	useEffect(() => {
		if (customerGroupsRef.current.length) {
			fetchBanners(customerGroupsRef.current.map((group) => String(group.id)))
		}
	}, [accountType, fetchBanners])
	return assets
}
