import cssVariables from '~/styles/variables.module.scss'

interface Props extends React.SVGProps<SVGSVGElement> {
	logoIconColor?: string
	logoTextColor?: string
}

export default function UARewardsNoBrackets({
	logoIconColor = cssVariables.red,
	logoTextColor = cssVariables.black,
	...svgProps
}: Props) {
	return (
		<svg viewBox="0 0 137 14" fill="none" {...svgProps} xmlns="http://www.w3.org/2000/svg" aria-hidden>
			<path
				d="M87.3436 12.5211H90.7425V0.225586H85.9088H84.9344C84.1518 0.225586 83.7222 0.448304 83.3155 1.2163L80.4767 6.57689L80.4844 6.56921V6.57689L77.354 12.5211H81.2593L86.1697 3.31292H87.3436L87.3512 7.98231H84.3436L85.9549 10.5628H87.3589V12.5211H87.3436Z"
				fill={logoTextColor}
			/>
			<path
				d="M67.1113 11.5303L69.9424 6.16203L70.0575 5.93931V12.521H73.8938C74.6764 12.521 75.106 12.2983 75.5127 11.5303L78.3438 6.16203L81.4742 0.217773H77.5689L73.1495 8.53513L73.1572 4.27277V0.225453H73.0728H70.0575H69.5741L65.1548 8.54281L65.1625 4.27277V0.225453H61.7098V12.5287H65.4847C66.275 12.521 66.697 12.2983 67.1113 11.5303Z"
				fill={logoTextColor}
			/>
			<path
				d="M103.018 0.217773H92.9598V12.521H96.3357V3.27439H102.489C103.118 3.27439 103.463 3.6123 103.463 4.24974V5.34029C103.463 5.97004 103.126 6.31564 102.489 6.31564H96.7807L98.1771 8.8961H100.816L103.187 12.521H106.832L104.415 8.82698C106.034 8.32778 106.832 7.08363 106.832 5.20205V3.91182C106.839 1.75376 105.558 0.217773 103.018 0.217773Z"
				fill={logoTextColor}
			/>
			<path
				d="M40.7715 0.217773H30.7129V12.521H34.0888V3.27439H40.2421C40.8713 3.27439 41.2165 3.6123 41.2165 4.24974V5.34029C41.2165 5.97004 40.8789 6.31564 40.2421 6.31564H34.5338L35.9302 8.8961H38.5772L40.948 12.521H44.6001L42.1833 8.82698C43.8022 8.32778 44.6001 7.08363 44.6001 5.20205V3.91182C44.5924 1.75376 43.3111 0.217773 40.7715 0.217773Z"
				fill={logoTextColor}
			/>
			<path
				d="M59.7917 3.31278H49.9555C49.8174 3.31278 49.7023 3.42798 49.7023 3.56622V9.18024C49.7023 9.31848 49.8174 9.43368 49.9555 9.43368H59.7917V12.5287H48.375C47.0093 12.5287 46.3264 11.6762 46.3264 10.4781V2.26831C46.3264 1.07024 47.0093 0.217773 48.375 0.217773H59.7917V3.31278ZM59.6229 7.49834H51.4133L50.1473 5.15596H58.3569L59.6229 7.49834Z"
				fill={logoTextColor}
			/>
			<path
				d="M119.415 8.35082V4.38798C119.415 3.6507 119.039 3.27439 118.302 3.27439H111.988V12.5133H108.612V0.217773H119.246C121.617 0.217773 122.798 1.7 122.798 3.77358V8.96522C122.798 11.0311 121.624 12.521 119.246 12.521H114.075L112.471 9.46441H118.31C119.039 9.45673 119.415 9.08042 119.415 8.35082Z"
				fill={logoTextColor}
			/>
			<path
				d="M128.775 3.22817H135.419L137 0.209961H128.246C125.706 0.209961 124.425 1.66915 124.425 3.71969V4.14977C124.425 6.19263 125.706 7.6595 128.246 7.6595H132.389C132.926 7.70558 133.225 8.02045 133.225 8.57341C133.225 9.17244 132.88 9.50268 132.251 9.50268H125.138L123.55 12.5132H132.788C135.327 12.5132 136.609 11.054 136.609 9.00348V8.57341C136.609 6.53055 135.327 5.06368 132.788 5.06368H128.645C128.108 5.0176 127.816 4.70272 127.816 4.14977C127.801 3.55841 128.138 3.22817 128.775 3.22817Z"
				fill={logoTextColor}
			/>
			<path
				d="M22.9561 2.15038C22.9561 2.15038 22.2809 1.63583 20.1326 0.844793C18.2529 0.153599 16.8334 0 16.8334 0L16.8411 4.06269C16.8411 4.63868 16.6877 5.15324 16.2503 5.75227C14.6545 5.1686 13.1353 4.80764 11.4857 4.80764C9.83613 4.80764 8.32465 5.1686 6.7211 5.75227C6.28377 5.15324 6.13032 4.63868 6.13032 4.06269L6.12265 0C6.12265 0 4.70324 0.153599 2.82348 0.844793C0.675179 1.63583 0 2.15038 0 2.15038C0.0920699 3.99357 1.70329 5.63707 4.24289 6.76602C1.70329 7.89497 0.0920699 9.53848 0 11.3817C0 11.3817 0.675179 11.8962 2.82348 12.6873C4.70324 13.3784 6.12265 13.532 6.12265 13.532L6.11498 9.46936C6.11498 8.90104 6.26843 8.37881 6.70576 7.77977C8.30164 8.36345 9.81312 8.72441 11.4704 8.72441C13.12 8.72441 14.6314 8.36345 16.235 7.77977C16.6723 8.37881 16.8258 8.89336 16.8258 9.46936L16.8181 13.532C16.8181 13.532 18.2375 13.3784 20.1173 12.6873C22.2579 11.8962 22.9408 11.3817 22.9408 11.3817C22.8487 9.53848 21.2375 7.89497 18.6979 6.76602C21.2528 5.63707 22.864 4.00125 22.9561 2.15038ZM15.2606 6.76602C15.1762 6.82746 15.0764 6.90426 14.9537 6.98874C14.1634 7.54938 13.0202 7.93337 11.5318 7.93337H11.4857H11.4397C9.95122 7.93337 8.80035 7.54938 8.01775 6.98874C7.89499 6.90426 7.79525 6.82746 7.71085 6.76602C7.79525 6.70458 7.90267 6.62778 8.01775 6.5433C8.80802 5.98267 9.95122 5.59867 11.4397 5.59867H11.4857H11.5318C13.0202 5.59867 14.1711 5.98267 14.9537 6.5433C15.0764 6.62778 15.1762 6.70458 15.2606 6.76602Z"
				fill={logoIconColor}
			/>
		</svg>
	)
}
