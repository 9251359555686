import dynamic from 'next/dynamic'
import React, { useMemo, useState } from 'react'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'
import { ModalDisplay } from '~/lib/enums/modal.enum'
import { noop } from '~/lib/utils'
import type { EnrollmentStatusChangeHandlerFunc } from '~/components/shared/UserAuthModal'

const AuthModalDynamic = dynamic(() => import('~/components/shared/UserAuthModal'))

export type AuthModalContextType = {
	showLoginModal: (
		handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
		enrollmentSource?: EnrollmentSource,
	) => void
	showRegisterModal: (
		handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
		enrollmentSource?: EnrollmentSource,
	) => void
	showSuccessfulRegistrationModal: () => void
	showPromptedLoginModal: (enrollmentSource?: EnrollmentSource) => void
	showLoyaltyEnrollmentModal: (
		handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
		enrollmentSource?: EnrollmentSource,
	) => void
	showLoyaltyEnrollmentSuccessful: () => void
}

export const AuthModalContext = React.createContext<AuthModalContextType>({
	showLoginModal: (_?: EnrollmentStatusChangeHandlerFunc, __?: EnrollmentSource) => {
		noop()
	},
	showRegisterModal: (_?: EnrollmentStatusChangeHandlerFunc, __?: EnrollmentSource) => {
		noop()
	},
	showPromptedLoginModal: (_?: EnrollmentSource) => {
		noop()
	},
	showSuccessfulRegistrationModal: noop,
	showLoyaltyEnrollmentModal: (_?: EnrollmentStatusChangeHandlerFunc, __?: EnrollmentSource) => {
		noop()
	},
	showLoyaltyEnrollmentSuccessful: noop,
})

// NOTE: Whenever we add a new type of auth modal, include it here.
/* const AuthModals = [
	ModalDisplay.Login,
	ModalDisplay.Register,
	ModalDisplay.SuccessfulRegistration,
	ModalDisplay.PromptedLogin,
	ModalDisplay.LoyaltyEnrollment,
	ModalDisplay.LoyaltyEnrollmentSuccessful,
] */

export function AuthModalProvider({ children }) {
	const [modalStatus, setModalStatus] = useState<ModalDisplay>(ModalDisplay.Closed)
	const [enrollmentHandler, setEnrollmentHandler] = useState<EnrollmentStatusChangeHandlerFunc>()
	const [source, setSource] = useState<EnrollmentSource>(EnrollmentSource.ECOMMFALLBACK)
	return (
		<AuthModalContext.Provider
			value={useMemo(
				() => ({
					showLoginModal: (
						handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
						enrollmentSource?: EnrollmentSource,
					) => {
						setSource(enrollmentSource !== undefined ? enrollmentSource : EnrollmentSource.ECOMMFALLBACK)
						setEnrollmentHandler(() => handleEnrollmentStatusChange)
						setModalStatus(ModalDisplay.Login)
					},
					showRegisterModal: (
						handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
						enrollmentSource?: EnrollmentSource,
					) => {
						setSource(enrollmentSource !== undefined ? enrollmentSource : EnrollmentSource.ECOMMFALLBACK)
						setEnrollmentHandler(() => handleEnrollmentStatusChange)
						setModalStatus(ModalDisplay.Register)
					},
					showSuccessfulRegistrationModal: () => setModalStatus(ModalDisplay.SuccessfulRegistration),
					showPromptedLoginModal: (enrollmentSource?: EnrollmentSource) => {
						setSource(enrollmentSource !== undefined ? enrollmentSource : EnrollmentSource.ECOMMFALLBACK)
						setModalStatus(ModalDisplay.PromptedLogin)
					},
					showLoyaltyEnrollmentModal: (
						handleEnrollmentStatusChange?: EnrollmentStatusChangeHandlerFunc,
						enrollmentSource?: EnrollmentSource,
					) => {
						setSource(enrollmentSource !== undefined ? enrollmentSource : EnrollmentSource.ECOMMFALLBACK)
						setEnrollmentHandler(() => handleEnrollmentStatusChange)
						setModalStatus(ModalDisplay.LoyaltyEnrollment)
					},
					showLoyaltyEnrollmentSuccessful: () => setModalStatus(ModalDisplay.LoyaltyEnrollmentSuccessful),
					// NOTE: Whenever we add a new type of auth modal, include it here.
				}),
				[],
			)}
		>
			<AuthModalDynamic
				modalStatus={modalStatus}
				setModalStatus={setModalStatus}
				enrollLoyalty={!!enrollmentHandler}
				handleSuccessEnrollment={enrollmentHandler}
				enrollmentSource={source}
			/>
			{children}
		</AuthModalContext.Provider>
	)
}

export function useAuthModals() {
	return React.useContext(AuthModalContext)
}
