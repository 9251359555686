import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function GreenCheckCircleIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<g transform="translate(.5)" fillRule="nonzero" fill="none">
				<circle fill="var(--color-green)" cx="8" cy="8" r="8" />
				<g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
					<path d="m5.53 8.143 1.764 1.765M7.294 9.908l3.921-3.92" />
				</g>
			</g>
		</svg>
	)
}

export default GreenCheckCircleIcon
