/* eslint-disable no-underscore-dangle */
import { createClientLogger } from '~/lib/logger'
import { McpSitewideBannerSchema, type McpSitewideBannerSafe } from './schema'
import { zodParseHandler } from '~/lib/schemas/utils'

/**
 * Create a logger instance.
 */
const logger = createClientLogger('mcp')

/**
 * Class representing a SitewideBanner implementation.
 */
export class SitewideBannerImpl {
	/**
	 * @private
	 * @type {McpSitewideBannerSafe | undefined}
	 */
	private _parsedData: McpSitewideBannerSafe | undefined

	/**
	 * Create a SitewideBannerImpl.
	 * @param {unknown} data - The data to be parsed.
	 */
	constructor(data: unknown) {
		try {
			this._parsedData = zodParseHandler(McpSitewideBannerSchema, data, {
				message: 'Failed to parse data using McpSitewideBannerSchema',
			})
		} catch (e) {
			logger.error(e, 'Failed to parse data using McpSitewideBannerSchema')
		}
	}

	/**
	 * Get the raw parsed data.
	 * @return {McpSitewideBannerSafe | undefined} The parsed data.
	 */
	protected get raw() {
		return this._parsedData
	}

	/**
	 * Get the body.
	 * @return {string | undefined} The body.
	 */
	get body() {
		return this.raw?.body
	}

	/**
	 * Get the campaign.
	 * @return {string | undefined} The campaign.
	 */
	get campaign() {
		return this.raw?.campaign
	}

	/**
	 * Get the experience.
	 * @return {string | undefined} The experience.
	 */
	get experience() {
		return this.raw?.experience
	}

	/**
	 * Get the header promo tooltip.
	 * @return {string | undefined} The header promo tooltip.
	 */
	get headerPromoTooltip() {
		return this.raw?.headerPromoTooltip
	}

	/**
	 * Get the header promo tooltip title.
	 * @return {string | undefined} The header promo tooltip title.
	 */
	get headerPromoTooltipTitle() {
		return this.raw?.headerPromoTooltipTitle
	}

	/**
	 * Get the id.
	 * @return {string | undefined} The id.
	 */
	get id() {
		return this.raw?.id
	}

	/**
	 * Get the promo mobile message.
	 * @return {string | undefined} The promo mobile message.
	 */
	get promoMobileMessage() {
		return this.raw?.promoMobileMessage
	}

	/**
	 * Get the user group.
	 * @return {string | undefined} The user group.
	 */
	get userGroup() {
		return this.raw?.userGroup
	}
}
