import { sendMcpEvents } from '~/lib/client-only/mcp/evergage'
import { McpCampaignStatType, McpCampaignStatUserGroup } from '~/lib/client-only/mcp/mcp.constants'
import type { AnalyticsContext } from '~/lib/client-server/cms/modules'

/**
 * Fires an `Impression` CampaignStatEvent triggered on initial render. Indicates the campaign is shown to the user.
 * Checks if campaign exists before sending data to MCP.
 * @param {AnalyticsContext} context - The context for the event.
 */
export function fireImpressionEvent(context: AnalyticsContext) {
	if (context?.campaign && context?.experience) {
		sendMcpEvents({
			experienceId: context.experience,
			stat: McpCampaignStatType.IMPRESSION,
			// For the personalization hero use case, we always display the personalization content, also known as the Test group, with no control. As a result, it will always be false, but it is configured to be future-proof for controlled user groups as well.
			control: context?.userGroup === McpCampaignStatUserGroup.CONTROL,
		})
	}
}

/**
 * Fires a `Clickthrough` CampaignStatEvent triggered by clicks.
 * Checks if campaign exists before sending data to MCP.
 * @param {AnalyticsContext} context - The context for the event.
 */
export function fireClickthroughEvent(context: AnalyticsContext) {
	if (context?.campaign && context?.experience) {
		sendMcpEvents({
			experienceId: context.experience,
			stat: McpCampaignStatType.CLICKTHROUGH,
			// For the personalization hero use case, we always display the personalization content, also known as the Test group, with no control. As a result, it will always be false, but it is configured to be future-proof for controlled user groups as well.
			control: context?.userGroup === McpCampaignStatUserGroup.CONTROL,
		})
	}
}
