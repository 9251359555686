import type { ExtractStates } from '~/lib/client-server/statemachine/types'
import { createActor } from 'xstate'
import type { StateMachine, AnyActorLogic } from 'xstate'

/**
 * Extracts the state enum from a state machine object.
 * @param machine - The state machine object.
 * @returns The state enum object.
 * @example
 * // Define a state machine object
 * const stateMachine = {
 *   definition: {
 *     states: {
 *       idle: { type: 'atomic' },
 *       active: { type: 'atomic' },
 *       paused: { type: 'atomic' },
 *       running: {
 *         type: 'compound',
 *         states: {
 *           started: { type: 'atomic' },
 *           stopped: { type: 'atomic' }
 *         }
 *       }
 *     }
 *   }
 * };
 *
 * // Extract the state enum
 * const stateEnum = extractStateEnumFromMachine(stateMachine);
 * console.log(stateEnum);
 * // Output: { idle: "idle", active: "active", paused: "paused", running: { started: "started", stopped: "stopped" } }
 */
export function extractStateEnumFromMachine<T extends { definition: { states: object } }>(
	machine: T,
): ExtractStates<T> {
	function extractStates(states: object): ExtractStates<T> {
		return Object.keys(states).reduce((acc, stateKey) => {
			const state = states[stateKey]
			if (state.type === 'compound' && Object.keys(state.states).length > 0) {
				acc[stateKey] = extractStates(state.states)
			} else {
				acc[stateKey] = stateKey
			}
			return acc
		}, {}) as ExtractStates<T>
	}

	return extractStates(machine.definition.states)
}

/**
 * Creates a test actor start function for use in tests. It is important to definition the state machine type to ensure the correct context is passed.
 *
 * @template T - The type of the state machine.
 * @param {T} machine - The state machine.
 * @returns {Function} - The test actor start function.
 *
 * @example
 * type MachineContext {
 * 	foo: string;
 * }
 *
 * export const contentSlotStateMachine = setup({
 * 		// NOTE: It is incredibly important to define the correct context type here
 * 		types: {
 *			context: {} as MachineContext,
 *			input: {} as MachineContext,
 *	 	},
 *	 	actions: {
 *			ignorePersonalizedContent: assign({}),
 *	 	},
 * }).createMachine({
 * 		id: 'contentSlotMachine',
 * 		initial: 'loading',
 * 		context: {
 * 			foo: 'bar',
 * 		},
 * 		states: {
 * 			initial: {
 * 				on: {
 * 					SOME_TRANSITION: 'someState',
 * 				},
 * 			},
 * 			someState: {
 * 				type: 'final',
 * 			},
 * 		},
 * });
 *
 * // Create a test actor start factory function for a state machine
 * const start = testActorStartFactory(myStateMachine);
 *
 * // Start the test actor with an initial context
 * const initialContext = { foo: 'bar' };
 * const testActor = start(initialContext);
 */
export function testActorStartFactory<T extends AnyActorLogic>(
	machine: T extends StateMachine<
		infer _TContext,
		infer _TEvent,
		infer _TChildren,
		infer _TActor,
		infer _TAction,
		infer _TGuard,
		infer _TDelay,
		infer _TStateValue,
		infer _TTag,
		infer _TInput,
		infer _TOutput,
		infer _TEmitted,
		infer _TMeta,
		infer _TResolvedTypesMeta
	>
		? T
		: never,
) {
	return (
		initialContext: T extends StateMachine<
			infer _TContext,
			infer _TEvent,
			infer _TChildren,
			infer _TActor,
			infer _TAction,
			infer _TGuard,
			infer _TDelay,
			infer _TStateValue,
			infer _TTag,
			infer TInput,
			infer _TOutput,
			infer _TEmitted,
			infer _TMeta,
			infer _TResolvedTypesMeta
		>
			? TInput
			: never,
	) => startTestActor(machine, initialContext)
}

/**
 * Starts a test actor for the given state machine with the initial context.
 *
 * @template T - The type of the state machine.
 * @param {T} machine - The state machine to create the actor for.
 * @param {TInput} initialContext - The initial context for the actor.
 * @returns {TActor} - The created actor.
 *
 * @example
 * // Create a state machine
 * const stateMachine = createStateMachine(...);
 *
 * // Create an actor with initial context
 * const actor = startTestActor(stateMachine, initialContext);
 *
 * // Start the actor
 * actor.start();
 *
 * // Use the actor
 * actor.sendEvent(...);
 */
export function startTestActor<T extends AnyActorLogic>(
	machine: T extends StateMachine<
		infer _TContext,
		infer _TEvent,
		infer _TChildren,
		infer _TActor,
		infer _TAction,
		infer _TGuard,
		infer _TDelay,
		infer _TStateValue,
		infer _TTag,
		infer _TInput,
		infer _TOutput,
		infer _TEmitted,
		infer _TMeta,
		infer _TResolvedTypesMeta
	>
		? T
		: never,
	initialContext: T extends StateMachine<
		infer _TContext,
		infer _TEvent,
		infer _TChildren,
		infer _TActor,
		infer _TAction,
		infer _TGuard,
		infer _TDelay,
		infer _TStateValue,
		infer _TTag,
		infer TInput,
		infer _TOutput,
		infer _TEmitted,
		infer _TMeta,
		infer _TResolvedTypesMeta
	>
		? TInput
		: never,
) {
	const options = {
		input: initialContext,
	}
	const actor = createActor(machine, options)
	actor.start()
	return actor
}

/**
 * Checks if the actor state matches any of the provided states.
 *
 * @param actorState - The actor state to check.
 * @param states - The states to check against.
 * @returns {boolean} - True if the actor state matches any of the provided states.
 *
 * @example
 *  const [actorState] = useReactiveActor(...);
 *  const shouldShowMobileHeaderBanner = matchesAny(actorState, [
 *  { mobile: 'showSsfcHeader' },
 *  { mobile: 'showSsfcPromo' },
 *  { mobile: 'showMcpPromo' },
 *  { mobile: 'cycleSsfcHeaderAndPromo' },
 *  { mobile: 'cycleSsfcHeaderAndMcpPromo' },
 *  { mobile: 'cycleAllPromoData' },
 *  ])
 */
export function matchesAny(actorState, states) {
	return states.some((state) => actorState.matches(state))
}
