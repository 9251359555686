import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

const SuccessIcon = memo(function SuccessIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<circle cx="50%" cy="50%" r="48" fill="var(--color-white)" stroke="currentcolor" strokeWidth="4" />
			<path
				d="M31.25,50 L43.7,62.5 L68.75,37.5"
				fill="none"
				stroke="currentcolor"
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
})

export default SuccessIcon
