import { memo } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

const AlertIcon = memo(function AlertIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<circle cx="50%" cy="50%" r="48" fill="var(--color-white)" stroke="currentcolor" strokeWidth="4" />
			<path d="M50,25 V60 m0,6 V75" stroke="currentcolor" strokeWidth="6" />
		</svg>
	)
})

export default AlertIcon
