import(/* webpackMode: "eager", webpackExports: ["useLocale"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/hooks/useLocale.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientLayout"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/ClientLayout.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Footer/AppDetails.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Footer/collapsible/FooterLinksSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Footer/PaymentMethods.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Header/HeaderBanner/HeaderBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/layouts/NavbarLayout/Header/PromoBanner/PromoBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/primitives/Image/Image.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/primitives/LocaleLink/LocaleLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PersonalizationProvider"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/providers/PersonalizationProvider/PersonalizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/shared/EmailSignup/EmailFooter/EmailFooter.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/shared/HelpCenterLink/HelpCenterLink.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/thirdparty/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/thirdparty/Fastly/ChallengeContainer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/components/thirdparty/QualtricsFeedback/QualtricsFeedback.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/seabiscuit-pwa/seabiscuit-pwa/styles/variables.module.scss");
