export enum ModalDisplay {
	Closed,
	Register,
	Login,
	PromptedLogin,
	SuccessfulRegistration,
	PointsHistory,
	RewardRedemption,
	RewardByQuery,
	Unenroll,
	LoyaltyEnrollment,
	LoyaltyEnrollmentSuccessful,
}
