import { setup } from 'xstate'

/**
 * Represents the context for a banners state machine.
 */
export interface BannersContext {
	/**
	 * Indicates whether ssfc header data is available.
	 */
	hasSsfcHeaderData: boolean
	/**
	 * Indicates whether ssfc promo data is available.
	 */
	hasSsfcPromoData: boolean
	/**
	 * Indicates whether mcp promo data is available.
	 */
	hasMcpPromoData: boolean
}

export const bannersStateMachine = setup({
	types: {
		context: <BannersContext>{},
		input: <BannersContext>{},
	},
	guards: {
		// Guard to check if only ssfc header data is available
		onlySsfcHeaderAvailable: ({ context }) =>
			context.hasSsfcHeaderData && !context.hasSsfcPromoData && !context.hasMcpPromoData,
		// Guard to check if only ssfc promo data is available
		onlySsfcPromoAvailable: ({ context }) =>
			!context.hasSsfcHeaderData && context.hasSsfcPromoData && !context.hasMcpPromoData,
		// Guard to check if only mcp promo data is available
		onlyMcpPromoAvailable: ({ context }) =>
			!context.hasSsfcHeaderData && !context.hasSsfcPromoData && context.hasMcpPromoData,
		// Guard to check if both ssfc header and promo data are available
		bothSsfcHeaderAndPromoAvailable: ({ context }) =>
			context.hasSsfcHeaderData && context.hasSsfcPromoData && !context.hasMcpPromoData,
		// Guard to check if both ssfc header and mcp promo data are available
		bothSsfcHeaderAndMcpPromoAvailable: ({ context }) =>
			context.hasSsfcHeaderData && !context.hasSsfcPromoData && context.hasMcpPromoData,
		// Guard to check if both ssfc and mcp promo data are available
		bothSsfcAndMcpPromoAvailable: ({ context }) =>
			!context.hasSsfcHeaderData && context.hasSsfcPromoData && context.hasMcpPromoData,
		// Guard to check if all data is available
		allDataAvailable: ({ context }) => context.hasSsfcHeaderData && context.hasSsfcPromoData && context.hasMcpPromoData,
		// // Guard to check if ssfc header data is available
		hasSsfcHeaderData: ({ context }) => context.hasSsfcHeaderData,
		hasNothing: ({ context }) => !context.hasSsfcHeaderData && !context.hasSsfcPromoData && !context.hasMcpPromoData,
	},
}).createMachine({
	context: ({ input }) => ({
		...input,
	}),
	id: 'banners',
	type: 'parallel',
	states: {
		// Mobile state machine
		mobile: {
			initial: 'checkingData',
			states: {
				checkingData: {
					always: [
						{
							// Transition to showSsfcHeader if only ssfc header data is available
							target: 'showSsfcHeader',
							guard: { type: 'onlySsfcHeaderAvailable' },
						},
						{
							// Transition to showSsfcPromo if only ssfc promo data is available
							target: 'showSsfcPromo',
							guard: { type: 'onlySsfcPromoAvailable' },
						},
						{
							// Transition to cycleSsfcHeaderAndPromo if both ssfc header and promo data are available
							target: 'cycleSsfcHeaderAndPromo',
							guard: { type: 'bothSsfcHeaderAndPromoAvailable' },
						},
						{
							// Transition to showMcpPromo if only mcp promo data is available
							target: 'showMcpPromo',
							guard: { type: 'onlyMcpPromoAvailable' },
						},
						{
							// Transition to cycleSsfcHeaderAndMcpPromo if both ssfc header and mcp promo data are available
							target: 'cycleSsfcHeaderAndMcpPromo',
							guard: { type: 'bothSsfcHeaderAndMcpPromoAvailable' },
						},
						{
							// Transition to cycleSsfcHeaderAndMcpPromo if both ssfc promo and mcp promo data are available
							target: 'cycleSsfcAndMcpPromo',
							guard: { type: 'bothSsfcAndMcpPromoAvailable' },
						},
						{
							// Transition to cycleAllData if all data is available
							target: 'cycleAllData',
							guard: { type: 'allDataAvailable' },
						},
						{
							// Transition to showNothing if no data is available
							target: 'showNothing',
							guard: { type: 'hasNothing' },
						},
					],
				},
				// Final state to show ssfc header
				showSsfcHeader: { type: 'final' },
				// Final state to show ssfc promo
				showSsfcPromo: { type: 'final' },
				// Final state to cycle ssfc header and promo
				cycleSsfcHeaderAndPromo: { type: 'final' },
				// Final state to show mcp promo
				showMcpPromo: { type: 'final' },
				// Final state to cycle ssfc header and mcp promo
				cycleSsfcHeaderAndMcpPromo: { type: 'final' },
				// Final state to cycle ssfc and mcp data
				cycleSsfcAndMcpPromo: { type: 'final' },
				// Final state to cycle all data
				cycleAllData: { type: 'final' },
				// Final state to show nothing
				showNothing: { type: 'final' },
			},
		},
		// Desktop state machine
		desktop: {
			type: 'parallel',
			states: {
				// State machine to show ssfc header
				header: {
					initial: 'checkingHeaderData',
					states: {
						checkingHeaderData: {
							always: {
								// Transition to showSsfcHeader if ssfc header data is available
								target: 'showSsfcHeader',
								guard: { type: 'hasSsfcHeaderData' },
							},
						},
						// Final state to show ssfc header
						showSsfcHeader: { type: 'final' },
					},
				},
				// State machine to show promo
				promo: {
					initial: 'checkingPromoData',
					states: {
						checkingPromoData: {
							always: [
								{
									// Transition to showSsfcPromo if only ssfc promo data is available
									target: 'showSsfcPromo',
									guard: { type: 'onlySsfcPromoAvailable' },
								},
								{
									// Transition to cycleSsfcAndMcpPromo if both ssfc and mcp promo data are available
									target: 'cycleSsfcAndMcpPromo',
									guard: { type: 'bothSsfcAndMcpPromoAvailable' },
								},
								{
									// Transition to showMcpPromo if only mcp promo data is available
									target: 'showMcpPromo',
									guard: { type: 'onlyMcpPromoAvailable' },
								},
								{
									// Transition to showNothing if no data is available
									target: 'showNothing',
									guard: { type: 'hasNothing' },
								},
							],
						},
						// Final state to show ssfc promo
						showSsfcPromo: { type: 'final' },
						// Final state to cycle ssfc and mcp promo
						cycleSsfcAndMcpPromo: { type: 'final' },
						// Final state to show mcp promo
						showMcpPromo: { type: 'final' },
						// Final state to show nothing
						showNothing: { type: 'final' },
					},
				},
			},
		},
	},
})
